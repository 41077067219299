import * as React from "react";
import * as style from "./styles/legal.module.scss";
import { graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection";
import BoundedSection from "components/BoundedSection";

const SecurityPage = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper
      seoTitle={t("legal.seo.title")}
      seoDescription={t("legal.seo.description")}
    >
      <HeaderSection
        title={t("legal.header.title")}
        description={<Trans i18nKey="legal.header.description" />}
      />
      <BoundedSection className={style.LegalInfo}>
        <p>Gracias por visitar este sitio web.</p>
        <p>
          Lee detenidamente los Términos y Condiciones que constan en este
          documento, ya que el uso de este sitio web implica la expresa y plena
          aceptación de los mismos, en la versión publicada en el momento en que
          accedes a todas y cada una de las páginas web localizables bajo el
          dominio trileucosolutions.com y sus respectivos subdominios y
          subdirectorios. Le recomendamos que vuelva a leer este documento cada
          vez que acceda al sitio web para comprobar si ha habido cambios en las
          condiciones de uso y lo abandone si no está de acuerdo con dichos
          cambios. Si creemos que ciertas modificaciones son importantes,
          actualizaremos la fecha de "Última modificación" en la parte superior
          de esta página. Toda persona que acceda a este sitio web asume el
          papel de usuario, comprometiéndose a la observancia y cumplimiento
          riguroso de las disposiciones aquí dispuestas, así como a cualquier
          otra disposición legal que fuera de aplicación.
        </p>
        <p>
          Trileuco Solutions, S.L.U. se reserva el derecho de modificar
          cualquier tipo de información que pudiera aparecer en el sitio web,
          sin que exista obligación de preavisar o poner en conocimiento de los
          usuarios dichas obligaciones, entendiéndose como suficiente la
          publicación en su sitio web.
        </p>
        <h3>
          <span>01.</span>Identificación
        </h3>
        <p>
          En cumplimiento con el deber de información recogido en artículo 10 de
          la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
          Información y de Comercio Electrónico con la legislación vigente en
          materia de protección de datos, en concreto, la Ley orgánica 3/2018,
          de 5 de diciembre, de protección de datos personales y garantía de los
          derechos digitales (LOPDGDD) que hace plenamente efectiva la
          aplicación del Reglamento (UE) 2016/679 del Parlamento Europeo y del
          Consejo de 27 de abril de 2016 relativo a la protección de las
          personas físicas en el que respeta al tratamiento de datos personales
          y a la libre circulación de estos datos (RGPD), le informamos, a
          continuación, nuestros datos identificativo y de contacto:
        </p>
        <p>
          <strong>Titular de la página web:</strong> Trileuco Solutions, S.L.U.
          (en adelante LA EMPRESA)
          <br />
          <strong>Nombre Comercial:</strong> Trileuco Solutions
          <br />
          <strong>CIF:</strong> B-70233416
          <br />
          <strong>Datos mercantiles:</strong> Inscrita en el Registro Mercantil
          de A Coruña el 11 de diciembre de 2009, en el Tomo 3.362 del Archivo,
          Sección General, folio 155, hoja número C-45.707, inscripción 1ª, e
          identificada fiscalmente por el Ministerio de Economía y Hacienda
        </p>

        <h3>
          <span>02.</span>Comunicación
        </h3>
        <p>
          Para contactar con nosotros para cualquier cuestión que haga
          referencia al contenido de este Aviso Legal, ponemos a su disposición
          diferentes medios de contacto que detallamos a continuación:
        </p>
        <p>
          <strong>Teléfono:</strong> 881 893 736
          <br />
          <strong>Correo electrónico:</strong> info@trileucosolutions.com
          <br />
          <strong>Domicilio Social:</strong> Avenida de Monelos 77-79, 8ºIZQ
          15009 A Coruña
          <br />
          <strong>Página Web:</strong> https://trileucosolutions.com
        </p>
        <p>
          Todas las notificaciones y comunicaciones facilitadas en este apartado
          se considerarán eficaces, a todos los efectos, cuando se realicen a
          través de cualquier medio de los detallados anteriormente.
        </p>
        <p>
          Por el hecho de acceder, ver o utilizar los materiales o servicios
          accesibles en o a través de este sitio web o a los contenidos
          accesibles u otras páginas web o aplicaciones informáticas, el usuario
          pone de manifiesto que comprende y acepta este documento como
          equivalente legal de un documento firmado por escrito y vinculante
        </p>

        <h3>
          <span>03.</span>Navegación
        </h3>
        <p>
          Nuestra página web cuenta con recursos técnicos razonables para
          proporcionar una navegación segura y proteger toda la información
          recogida. No obstante, debe tener en cuenta que, si bien aplicamos e
          implementamos las medidas para proteger su información, ninguna página
          web, transmisión por Internet, sistema informático o conexión
          inalámbrica es absolutamente segura. LA EMPRESA no se responsabilizará
          en casos de interrupción del servicio, mal funcionamiento o cualquier
          inconveniente del que venga a ocurrir por causas ajenas a nosotros.
          Asimismo, nos excluimos de cualquier responsabilidad por daños o
          perjuicios derivados de presencia de virus o cualquier tipo de
          incidente originado o introducido en el sistema informático por
          intermedio de hackers o de terceros que accedan de manera dolosa a
          integridad a nuestra página web.
        </p>
        <p>
          Vínculos a otras páginas web: Para su comodidad, esta página web
          contiene vínculos con otras páginas que pueden poseer políticas de
          privacidad diferentes. En estos casos, LA EMPRESA actúa como prestador
          de servicios de intermediación de conformidad con el artículo 17 de la
          Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
          Información y el Comercio Electrónico "LSSI" y sólo será responsable
          de los contenidos y servicios suministrados en las webs enlazadas en
          la medida en que tenga conocimiento efectivo de la ilicitud y no haya
          desactivado el enlace con la diligencia debida. En el supuesto de que
          el Usuario considere que existe una web enlazada con contenidos
          ilícitos o inadecuados podrá comunicárselo a LA EMPRESA, sin que en
          ningún caso esta comunicación conlleve la obligación de retirar el
          correspondiente enlace.
        </p>
        <p>
          En ningún caso, la existencia de webs enlazadas debe presuponer la
          existencia de acuerdos con los responsables o titulares de los mismos,
          ni la recomendación, promoción o identificación de LA EMPRESA con las
          manifestaciones, contenidos o servicios proveídas.
        </p>
        <p>
          LA EMPRESA no conoce los contenidos y servicios de las webs enlazadas
          y por tanto no se hace responsable por los daños producidos por la
          ilicitud, calidad, indisponibilidad, error e inutilidad de los
          contenidos y/o servicios de las webs enlazadas ni por cualquier otro
          daño que no sea directamente imputable a LA EMPRESA. Asimismo,
          recomendamos examinar sus respectivos términos y condiciones legales.
          LA EMPRESA no se hace responsable ni del contenido, ni de cualquier
          otro aspecto relacionado con páginas web pertenecientes o dirigidas
          por terceros. Asimismo le informamos que no podemos ejercer control
          alguno acerca de su mantenimiento y contenido, por lo tanto, no
          podemos garantizar que los hiperenlaces, punteros u otras funciones de
          enlace dispuestas en esos sitios, sean correctas en el momento de su
          acceso y que estén libres de virus u otros elementos lesivos que
          pudieran causar daños o alteraciones en su equipo informático. Sin
          embargo, y en cumplimiento de lo dispuesto en los artículos 11 y 16 de
          la LSSICE, se pone a disposición de todos los usuarios, autoridades y
          fuerzas de seguridad, colaborando de forma activa en la retirada o, en
          su caso, bloqueo de todos aquellos contenidos que puedan afectar o
          contravenir la legislación nacional o internacional, los derechos de
          terceros o la moral y el orden público. En caso de que el usuario
          considere que existe en el sitio web algún contenido que pudiera ser
          susceptible de esta clasificación, se ruega lo notifique de forma
          inmediata al administrador del sitio web.
        </p>
        <p>
          Este sitio web se ha revisado y probado para que funcione
          correctamente. En principio, puede garantizarse el correcto
          funcionamiento los 365 días del año, 24 horas al día. Sin embargo, LA
          EMPRESA no descarta la posibilidad de que existan ciertos errores de
          programación, o que acontezcan causas de fuerza mayor, catástrofes
          naturales, huelgas o circunstancias semejantes que hagan imposible el
          acceso a la página web.
        </p>
        <p>
          <strong>Obligaciones del usuario:</strong> El Usuario se obliga a
          hacer un uso correcto de la página web de conformidad con la Ley y el
          presente Aviso Legal. El Usuario responderá frente a LA EMPRESA o
          frente a terceros de cualesquiera daños y perjuicios que pudieran
          causarse como consecuencia del incumplimiento de dicha obligación.
        </p>
        <p>
          Queda expresamente prohibido el uso de la página web con fines lesivos
          de bienes o intereses de LA EMPRESA o de terceros o que de cualquier
          otra forma sobrecarguen, dañen o inutilicen las redes, servidores y
          demás equipos informáticos (hardware) o productos y aplicaciones
          informáticas (software) de LA EMPRESA o de terceros.
        </p>
        <h4>Direcciones IP</h4>
        <p>
          Los servidores del sitio web podrán detectar de manera automática la
          dirección IP y el nombre de dominio utilizados por el usuario. Una
          dirección IP es un número asignado automáticamente a un ordenador
          cuando este se conecta a Internet. Toda esta información se registra
          en un fichero de actividad del servidor debidamente inscrito que
          permite el posterior procesamiento de los datos con el fin de obtener
          mediciones únicamente estadísticas que permitan conocer el número de
          impresiones de páginas, el número de visitas realizadas a los
          servidores web, el orden de visitas, el punto de acceso, etc.
        </p>

        <h3>
          <span>04.</span>Formularios de recogida de datos
        </h3>
        <p>
          Sin perjuicio de lo dispuesto en nuestra Política de Privacidad
          accesible desde nuestra página web, la utilización de ciertos
          servicios o solicitudes dirigidas a LA EMPRESA están condicionadas a
          la previa cumplimentación del correspondiente registro de Usuario.
        </p>
        <p>
          Toda la información que facilite el Usuario a través de los
          formularios de nuestra página web a los efectos anteriores o
          cualesquiera otros deberá ser veraz. A estos efectos, el Usuario
          garantiza la autenticidad de todos aquellos datos que comunique y
          mantendrá la información facilitada a LA EMPRESA perfectamente
          actualizada de forma que responda, en todo momento, a la situación
          real del Usuario. En todo caso será el Usuario el único responsable de
          las manifestaciones falsas o inexactas que realice y de los perjuicios
          que cause a LA EMPRESA o a terceros por la información que facilite.
        </p>

        <h3>
          <span>05.</span>Propiedad intelecitual
        </h3>
        <p>
          La totalidad de este sitio web (descripción de productos, texto,
          imágenes, marcas, logotipos, botones, archivos, colores, así como la
          estructura, selección, ordenación y presentación de sus contenidos)
          son propiedad de LA EMPRESA o de terceros que han licenciado o
          autorizado a nosotros para su uso. Todos los logotipos, marcas y
          diseños que aparecen en esta página web forman parte de los derechos
          de propiedad intelectual registrados por nosotros, quedando prohibida
          cualquier modalidad de explotación, es decir, la reproducción,
          distribución, comunicación pública y transformación sin la
          autorización expresa.
        </p>
        <p>
          El usuario se obliga a usar los contenidos de forma diligente,
          correcta y lícita y, en particular, se compromete a abstenerse de
          suprimir o manipular el copyright y demás datos identificativos de los
          derechos de LA EMPRESA o de sus titulares incorporados a los
          contenidos, así como los dispositivos técnicos de protección o
          cualesquiera mecanismos de información que pudieren incluirse en los
          contenidos.
        </p>
        <p>
          Asimismo el usuario se abstendrá de emplear los contenidos y, en
          particular, la información de cualquier clase obtenida a través del
          sitio para remitir publicidad, comunicaciones con fines comerciales,
          mensajes no solicitados dirigidos a una pluralidad de personas con
          independencia de su finalidad, así como a abstenerse de comercializar
          o divulgar de cualquier modo dicha información. Asimismo, LA EMPRESA
          podrá establecer, si lo considera oportuno, sin perjuicio de la única
          y exclusiva responsabilidad de los Usuarios, los filtros necesarios a
          fin de evitar que a través de sus páginas Web puedan verterse en la
          red contenidos u opiniones, considerados como racistas, xenófobos,
          discriminatorios, pornográficos, difamatorios o que, de cualquier
          modo, fomenten la violencia o la diseminación de contenidos claramente
          ilícitos o nocivos.
        </p>
        <p>
          LA EMPRESA reconoce a favor de sus titulares los correspondientes
          derechos de propiedad intelectual e industrial, no implicando su sola
          mención o aparición en el sitio web la existencia de derechos o
          responsabilidad alguna sobre los mismos, como tampoco respaldo,
          patrocinio o recomendación por parte del mismo.
        </p>
        <p>
          Para realizar cualquier tipo de observación respecto a posibles
          incumplimientos de los derechos de propiedad intelectual o industrial,
          así como sobre cualquiera de los contenidos del sitio web, puede
          hacerlo a través del correo electrónico info@trileucosolutions.com
        </p>

        <h3>
          <span>06.</span>Comunicaciones comerciales
        </h3>
        <p>
          Siempre que contemos con su consentimiento expreso (que se obtendrá
          mediante una casilla exclusiva presente en nuestros formularios),
          podremos usar sus datos personales para ponernos en contacto con usted
          en el futuro para la realización de acciones comerciales que puedan
          ser de su interés, siempre relacionadas con los productos y/o
          servicios que ofrece la empresa. En cualquier caso, usted siempre
          tendrá disponible la opción "dejar de recibir" estos mensajes
          electrónicos en la parte inferior de dichos mensajes o notificarlo a
          nosotros enviando un correo electrónico a la siguiente dirección:
          info@trileucosolutions.com Sin embargo, usted podrá seguir recibiendo
          avisos y correos electrónicos siempre que sean necesarios y esenciales
          para el mantenimiento de nuestras transacciones contractuales. De
          acuerdo con la Ley 34/2002, de 11 de julio, de servicios de la
          sociedad de la información y de comercio electrónico. "LSSI" LA
          EMPRESA no realiza ningún envío de comunicaciones publicitarias o
          promocionales por correo electrónico u otro medio de comunicación
          electrónica equivalente que previamente no hubieran sido solicitadas o
          expresamente autorizadas por los destinatarios de las mismas.
        </p>
        <p>
          Le rogamos tenga en cuenta que aunque decida no suscribirse o se dé de
          baja de las comunicaciones electrónicas promocionales o comerciales
          puede que LA EMPRESA siga necesitando contactar con usted como Usuario
          con información importante sobre transacciones relativas a su cuenta y
          a sus compras de productos, reserva de actividades o contratación de
          otros servicios.
        </p>

        <h3>
          <span>07.</span>Cookies
        </h3>
        <p>
          Nuestra política con relación al uso de cookies se expone en el
          documento de Política de cookies.
        </p>

        <h3>
          <span>08.</span>Protección de datos de carácter personal
        </h3>
        <p>
          Nuestra política con relación al tratamiento que realizamos de sus
          datos de carácter personal se expone en el documento Política de
          Privacidad.
        </p>

        <h3>
          <span>09.</span>Exclusión de responsabilidad
        </h3>
        <p>
          <strong>De la información:</strong> El acceso a la página web no
          implica la obligación por parte de LA EMPRESA de comprobar la
          veracidad, exactitud, adecuación, idoneidad, exhaustividad y
          actualidad de la información suministrada a través del mismo. Los
          contenidos de esta página son de carácter general y no constituyen, en
          modo alguno, la prestación de un servicio de asesoramiento legal o
          fiscal de ningún tipo, por lo que dicha información resulta
          insuficiente para la toma de decisiones personales o empresariales por
          parte del Usuario. LA EMPRESA no se responsabiliza de las decisiones
          tomadas a partir de la información suministrada en la página web ni de
          los daños y perjuicios producidos en el Usuario o terceros con motivo
          de actuaciones que tengan como único fundamento la información
          obtenida en la página web.
        </p>
        <p>
          <strong>De la calidad del servicio:</strong> El acceso a la página web
          no implica la obligación por parte de LA EMPRESA de controlar la
          ausencia de virus, gusanos o cualquier otro elemento informático
          dañino. Corresponde al Usuario, en todo caso, la disponibilidad de
          herramientas adecuadas para la detección y desinfección de programas
          informáticos dañinos. LA EMPRESA no se responsabiliza de los daños
          producidos en los equipos informáticos de los Usuarios o de terceros
          durante la prestación del servicio de la página web.
        </p>
        <p>
          <strong>De la disponibilidad del servicio:</strong> El acceso a la
          página web requiere de servicios y suministros de terceros, incluidos
          el transporte a través de redes de telecomunicaciones cuya fiabilidad,
          calidad, continuidad y funcionamiento no corresponde a LA EMPRESA. Por
          consiguiente, los servicios proveídos a través de la página web pueden
          ser suspendidos, cancelados o resultar inaccesibles, con carácter
          previo o simultaneo a la prestación del servicio de la página web. LA
          EMPRESA no se responsabiliza de los daños o perjuicios de cualquier
          tipo producidos en el Usuario que traigan causa de fallos o
          desconexiones en las redes de telecomunicaciones que produzcan la
          suspensión, cancelación o interrupción del servicio de la página web
          durante la prestación del mismo o con carácter previo.
        </p>

        <h3>
          <span>10.</span>Legislación y jurisdicción
        </h3>
        <p>
          Las presentes condiciones generales se regirán por lo aquí establecido
          y en su defecto por lo establecido en las Leyes españolas. Para
          cualquier controversia que pueda surgir en la interpretación o
          ejecución de las presentes condiciones, el usuario y LA EMPRESA
          acuerdan someterse expresamente a los Juzgados y Tribunales que
          pudieran corresponder de conformidad con la legislación aplicable.
        </p>
      </BoundedSection>
    </PageWrapper>
  );
};

export default SecurityPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
